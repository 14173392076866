import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import style from './PatientProfile.scss';
import { ReduxState } from '../../redux/types';
import { fetchPatientsAndCarePlansThunk } from '../../redux/carePlan/carePlanThunks';
import HorizontalTabs from '../../components/HorizontalTabs/HorizontalTabs';
import Feed from './Feed/Feed';
import { Text } from '../../components/Typography';
import { getPersonByIdSelector } from '../../redux/person/personSelector';
import { getPersonDetailsThunk } from '../../redux/person/personThunks';
import { getDefaultPatientProfileBreadCrumbs, getDefaultPatientProfileCrumb } from '../../utils/breadcrumbs';
import { getBreadcrumbsSelector } from '../../redux/oauth/oauthSelectors';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getInitials } from '../../utils/util';
import { Button } from '../../components/Button/Button';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const PatientProfile: React.FC<Props> = ({ breadCrumbs, personById, appendBreadCrumbs, fetchPatientsAndCarePlans, fetchPersonDetails }) => {
  const [isFetching, setIsFetching] = useState(true);

  const params = useParams();
  const navigate = useNavigate();
  const { userId } = params || {};

  const fullName = (userId && personById[userId]?.fullName) || '';
  const avatarUrl = '';

  const defaultCurrentCrumb = fullName ? getDefaultPatientProfileCrumb(fullName) : undefined;
  const defaultCrumbHistory = getDefaultPatientProfileBreadCrumbs(fullName);

  const tabs = [
    { label: 'Feed', content: <Feed /> },
    { label: 'Assessments', content: '' },
    { label: 'Devices', content: '' },
    { label: 'Details', content: '' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        await fetchPatientsAndCarePlans();
        if (userId) {
          await fetchPersonDetails(userId);
        }
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [userId]);

  const handleUserDetailsButtonClick = async () => {
    navigate(`/user/${userId}`);
  };

  return (
    <NavSideBarContentWrapper
      headerText=""
      appendBreadCrumbs={appendBreadCrumbs}
      defaultCurrentCrumb={defaultCurrentCrumb}
      defaultCrumbHistory={defaultCrumbHistory}
      breadCrumbs={breadCrumbs}
      headerClassName={style.header}
      contentClassName={style.navContent}
    >
      <div className={style.container}>
        {!isFetching && (
          <div className={style.mainContainer}>
            <div className={style.nameContainer}>
              <div className={style.nameWithAvatar}>
                <div className={style.avatar}>{avatarUrl ? <img src={avatarUrl} /> : getInitials(fullName)}</div>
                <Text variant="xl">{fullName}</Text>
              </div>
              <Button variant="outlined" onClick={handleUserDetailsButtonClick} disabled={!userId}>
                Patient Details
              </Button>
            </div>
            <HorizontalTabs tabs={tabs} tabsClassName={style.tabs} tabsContentClassname={style.tabsContent} />
          </div>
        )}
      </div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    breadCrumbs: getBreadcrumbsSelector(state),
    personById: getPersonByIdSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) =>
      appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory, isOldApp }),
    fetchPatientsAndCarePlans: fetchPatientsAndCarePlansThunk,
    fetchPersonDetails: getPersonDetailsThunk
  }
);

export default compose(connectRedux)(PatientProfile);
